import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"

export default function notFound() {
    return (
        <Layout>
            <div className="bg-primary position-absolute w-100" style={{height: '65px', top: 0}}/>
            <Container className="text-center">
                <Row className="justify-content-center align-items-center mb-5">
                    <Col lg={3}>
                        <h1 className="mr-5 text-right" style={{color: "#FFC000", fontSize: '10rem'}}>4</h1>
                    </Col>
                    <Col lg={4}>
                        <img src="/donut.png" alt="Donut" />
                    </Col>
                    <Col lg={3}>
                        <h1 className="text-left" style={{color: "#FFC000", fontSize: '10rem'}}>4</h1>
                    </Col>
                </Row>
                <p className="pb-5 w-50 mx-auto">It seems like we <b>donut</b> find what you searched. The page you were looking for doesn't exist, isn't available or was loading incorrectly.</p>
                <Link className="link-button" to="/">BACK HOME</Link>
            </Container>
        </Layout>
    )
}
